import api from '../api'
/**
 * 管理员用户相关api
 */
export class DeptApi extends api {
  getAll() {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/all`,
      method: 'get'
    })
  }
}

export default new DeptApi('dept')