<template>
  <el-container ref="container">
    <el-header ref="filterHeader" height="auto">
      <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" ref="newButton" height="auto" v-waves>新建</el-button>
    </el-header>
    <el-main>
      <el-row type="flex" :style="'height:' + tableHeight + 'px;'">
        <el-table
          ref="deptTable"
          row-key="id"
          border
          fit
          default-expand-all
          size="mini"
          highlight-current-row
          :data="groupDepts"
          @row-click="onRowClick"
          @select="onRowSelect"
          @selection-change="handleDeptChange"
          style="width:100%;overflow-y:auto"
        >
          <el-table-column type="index" width="50" align="center">
          </el-table-column>
          <el-table-column label="部门名称" min-width="150">
            <template #default="scope">
              <span class="noselect">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="父级部门" width="100" align="center">
            <template #default="scope">
              <span>{{scope.row.parent? scope.row.parent.name:'无'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="level" width="100" align="center">
            <template #default="scope">
              <span>{{scope.row.level === 0? '根部门':(scope.row.level === 1?'普通部门':'叶子部门')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="路径" fit :show-overflow-tooltip="true" prop="pathName">
            <template #default="scope">
              <span>{{scope.row.pathName}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="180px" class-name="fixed-width">
            <template #default="scope">
              <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-waves>编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteDept(scope.row)" v-waves>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-main>
  </el-container>
</template>

<style lang="scss" scoped>
</style>

<script>
import waves from '@/directive/waves' // 水波纹指令
import deptApi from '@/apis/dept/dept'
import { mapGetters } from 'vuex'

export default {
  directives: {
    waves
  },
  computed: {
    ...mapGetters(['mainContainerHeight']),
    tableHeight: function () {
      return this.mainContainerHeight - (this.$refs.filterHeader ? this.$refs.filterHeader.$el.clientHeight : 28) - 40
    }
  },
  watch: {
  },
  data () {
    return {
      clientHeight: 0,
      groupDepts: [],
      depts: [],
      apiDepts: [],
      selectedRows: [],
      apis: [],
      selectedApiId: undefined,
      selectedTab: undefined,
      showCancel: false
    }
  },
  methods: {
    /**
     * 选项卡点击事件处理
     * @param  {Object} tab 选项卡
     */
    handleTabClick (tab) {
      var index = Number.parseInt(tab.index)
      this.selectedApiId = this.apis[index].id
      this.loadCheckStatus()
    },
    /**
     * 新增按钮点击处理
     */
    handleCreate () {
      this.$router.pushTo(this.$router.routesConfig.deptNew)
    },
    /**
     * 修改按钮点击处理
     * @param {*} row 参数
     */
    handleUpdate (row) {
      this.$router.pushTo(this.$router.routesConfig.deptEdit, row.id)
    },
    /**
     * 删除按钮点击处理
     * @param {*} row 参数
     */
    deleteDept (row) {
      this.$confirm('删除后无法恢复,确定要删除该部门吗？', '警告', {
        type: 'warning'
      }).then(() => {
        deptApi.delete(row.id).then(res => {
          if (res.data) {
            this.ready()
          } else {
            this.$message.error({
              message: '删除部门失败，请稍后重试！！！',
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
    },
    /**
     * 选项变化事件处理
     * @param  {Array} selection 选择的行
     */
    handleDeptChange (selection) {
      this.selectedRows = selection
    },
    /**
     * 行被单击
     */
    onRowClick (row, column, event) {},
    onRowSelect (selection, row) {
      let select = selection.find(m => m.id === row.id)
      let recurseDeptSelection = m => {
        this.$refs.deptTable.toggleRowSelection(m, select)
        if (Array.isArray(m.children)) {
          m.children.forEach(recurseDeptSelection)
        }
      }
      Array.isArray(row.children) && row.children.forEach(recurseDeptSelection)
    },
    /**
     * 过滤出显示的部门
     */
    filterShowDept (depts) {
      let recurseFilter = m => {
        if (Array.isArray(m.children) && m.children.length > 0) {
          m.children = m.children.reduce(subDeptFilter, [])
        }
        return m
      }
      let subDeptFilter = (pre, next, index, arr) => {
        let showDepts = []
        showDepts.push(recurseFilter(next))
        return [...new Set([...pre, ...showDepts])]
      }

      return depts.reduce(subDeptFilter, [])
    },
    /**
     * 获取部门列表
     */
    async ready () {
      let deptReponse = await deptApi.getAll()
      this.groupDepts = deptReponse.data
      this.groupDepts = this.filterShowDept(this.groupDepts)
    }
  },
  mounted () {
    this.ready()
  }
}
</script>
